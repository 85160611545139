export default {
  contentOverview: 'CONTENT_OVERVIEW',
  calendar: 'CALENDAR',
  stories: 'STORIES',
  postsets: 'POSTSETS',
  tasks: 'TASKS',
  campaigns: 'CAMPAIGNS',
  websitecontents: 'websitecontents',
  newsletters: 'newsletters',
  videoprojects: 'videoprojects',
  ebooks: 'ebooks',
  webinars: 'webinars',
  podcasts: 'podcasts',
};
